<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md mb-4">
            <h3 class="fw-bold text-success mb-0">Accounts by date</h3>
          </div>
          <div class="col-2 mb-4">
            <select
              v-model="retreat"
              class="form-control me-2"
            >
              <option value="">All retreats</option>
              <option value="uk">UK</option>
              <option value="malta">Malta</option>
            </select>
          </div>
          <div class="col-auto mb-4 ms-auto">
            <date-picker
              ref="datePicker"
              v-model="range"
              is-range
              :popover="{ visibility: 'click', transition: 'fade' }"
              :input-props="{ class: 'form-control form-control-sm' }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="date-picker-wrap">
                  <input
                    :value="inputValue.start + ' - ' + inputValue.end"
                    v-on:click="togglePopover"
                    class="d-inline form-control disabled"
                    placeholder="End Date"
                    readonly
                  />
                </div> </template
            ></date-picker>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table" v-if="!busy">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Created Date</th>
                <th scope="col">Name</th>
                <th scope="col">Location</th>
                <th scope="col">Nights</th>
                <th scope="col">Total Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="b in bookings" :key="b.id">
                <th
                  @click="goToBooking(b.clients[0].id, b.booking.id)"
                  scope="row"
                >
                  {{ b.id }}
                </th>

                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  {{ b.created_at | formatDate }}
                </td>
                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  <span v-for="c in b.clients" :key="c.id">{{ c.name }} </span>
                </td>

                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  {{ b.booking.retreat }}
                </td>

                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  {{ b.booking.total_nights }}
                </td>

                <td @click="goToBooking(b.clients[0].id, b.booking.id)">
                  £{{ b.total_cost.toFixed(2) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <loading v-if="busy"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  data() {
    return {
      busy: false,
      bookings: [],
      popover: {
        visibility: "focus"
      },
      range: {
        start: this.$route.query.start ? this.$route.query.start : new Date(),
        end: this.$route.query.end ? this.$route.query.end : new Date()
      },
      retreat: ""
    };
  },
  computed: {
    totalTurnover() {
      return this.bookings.reduce((a, b) => {
        return a + parseInt(b.total_cost);
      }, 0);
    },

    totalPaid() {
      return this.bookings.reduce((a, b) => {
        return a + parseInt(b.total_paid);
      }, 0);
    },

    totalOutstanding() {
      return this.bookings.reduce((a, b) => {
        return a + parseInt(b.total_outstanding);
      }, 0);
    }
  },
  watch: {
    range: {
      handler: function(val, old) {
        this.fetchRetreatBookings();
      }
    },
    retreat: {
      handler: function(val, old) {
        this.fetchRetreatBookings();
      }
    }
  },
  methods: {
    open() {
      this.$refs["datePicker"].$el.querySelector("input").focus();
    },
    goToBooking(client, booking) {
      this.$router.push({
        path: "/clients/" + client,
        query: {
          "retreat-booking": booking
        },
        hash: "retreat-bookings"
      });
    },
    fetchRetreatBookings() {
      this.bookings = [];
      this.busy = true;
      this.$axios(
        process.env.VUE_APP_API_URL +
          "/reporting/retreat-bookings?start=" +
          moment(this.range.start).format("YYYY-MM-DD") +
          "&end=" +
          moment(this.range.end).format("YYYY-MM-DD") +
          "&retreat=" +
          this.retreat
      )
        .then(({ data }) => {
          this.bookings = data;
        })
        .finally(() => {
          this.busy = false;
        });
    },
    updateValues(range) {
      this.fetchRetreatBookings();
    }
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.fetchRetreatBookings();
  },
  filters: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("LL");
      }
      return "Not Set";
    },
    formatCallBackDate(date) {
      if (date) {
        return moment.utc(date).format("LLL");
      }
      return "Not Set";
    },
    formatType(type) {
      return type.replace(/_/g, " ");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    }
  },
  components: {
    Loading,
    DatePicker
  }
};
</script>

<style>
</style>